import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import { resetPrivateForm } from './modules/claims/components/PrivateDetails/claimDetailsHelpers/resetPrivateForm';
import { setResetClaimForm, setShowTotalOutstandingDialog, updateTeleplanInvoice } from './modules/claims/actions/claims.action.creators';
import { getSpeciality } from './modules/config/specialitiesConfig';
import { createMenu } from './modules/config/mainMenu';
import { t } from './service/localization/i18n';
import { isEmpty } from 'lodash';
import classes from './styles/AppTopbar.module.scss';
import metadata from './metadata.json';
import cx from 'classnames';
import { getTotalOutstanding } from './modules/utils/getTotalOutstanding';
import { logout } from './modules/auth/actions/auth.actions.creators';

const AppTopbar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { resetClaimForm, privateRecord, teleplanInvoice } = useSelector((state) => state.claims);
  const { isMobile } = useSelector((state) => state.core.window);
  const { details, loginAsAffiliateRequest } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state);
  const clinicName = clinic?.details?.ClinicName;
  const managed = clinic?.details?.Managed || 0;
  const user = details;
  const specialty = getSpeciality(user.Speciality);
  const bureauUser = sessionStorage.getItem('bau');
  // Check if the pathname is the root
  const isHomePage = location.pathname === '/';

  const onTopbarItemClick = (event, item) => {
    if (props.onTopbarItemClick) {
      //reset claim form
      if (resetClaimForm) {
        !isEmpty(privateRecord) && resetPrivateForm(); // reset private form
        dispatch(setResetClaimForm(false));
      }

      // Reset teleplan invoice
      if (!isEmpty(teleplanInvoice)) dispatch(updateTeleplanInvoice({}));

      props.onTopbarItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const settingsMenu = (label) => {
    const specs = user.Specialties?.split('|') || [];
    const account = createMenu(specs).find((item) => item.label === label);
    let settings = account.items.map((item) => {
      return (
        <li role="menuitem" key={item.label}>
          <button className="p-link" onClick={(e) => onTopbarItemClick(e, item)}>
            <i className={`pi ${item.icon}`} />
            {item.scope === 'speciality' && <span className="pr-3">•</span>}
            <span>{item.label}</span>
          </button>
        </li>
      );
    });
    if (label === 'Gear') {
      settings.push(
        <li key={`Version_${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}_${metadata.builtOn}`}>
          <div style={{ marginLeft: '2em', fontSize: 'smaller' }}>
            {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.builtOn}`}
            {metadata.branch && ` (${metadata.branch})`}
            <br/>{window.location.hostname}
          </div>
        </li>
      );
    }
    return settings;
  };

  return (
    <div className="layout-topbar">
      {/* <img alt="logo" src="assets/layout/images/logo-slim.png" className="mobile-logo" /> */}
      <img alt="logo" src="assets/layout/images/menu-logo.png" className={cx('mobile-logo mt-0', classes.logo)} onClick={() => history.push('/')} />

      <button tabIndex={-1} type="button" className="p-link menu-btn" onClick={props.onMenuButtonClick}>
        <i className="pi pi-bars"></i>
      </button>

      <button tabIndex={-1} type="button" className="p-link topbar-menu-btn" onClick={props.onTopbarMobileMenuButtonClick}>
        <i className="pi pi-user"></i>
      </button>

      <div className="layout-topbar-menu-wrapper">
        <ul className={cx('topbar-menu fadeInDown', { 'topbar-menu-active': props.topbarMenuActive })}>
          <li className={cx('profile-item', { 'active-topmenuitem': props.activeTopbarItem === 'profile' })}>
            <div className="p-mt--2">
              <Button
                className={cx('p-button-text mr-5 mt-0', classes.togglemenuArrow)}
                tabIndex={-1}
                icon={props.layoutMode === 'overlay' ? 'pi pi-arrow-right' : 'pi pi-arrow-left'}
                onClick={props.onToggleMenuClick}
              />
            </div>

            <button tabIndex={-1} type="button" className="p-link">
              <span onClick={(e) => onTopbarItemClick(e, 'profile')}>
                <span className="profile_image_wrapper">
                  {/* <img src="assets/layout/images/avatar.png" alt="avatar" /> */}
                  <i className={cx('pi pi-user pt-1 pl-2', classes.icons)} />
                </span>
                <span className="topbar-item-name profile-name">
                  {bureauUser && `${user?.FirstName} @ ${clinicName}`}
                  {!bureauUser && `${user?.LastName}, ${user?.FirstName} (${specialty.label})`}
                </span>
              </span>

              {/* Switch account */}
              {bureauUser && isHomePage && (
                <>
                  <Tooltip target=".switchboard" position="bottom" />
                  <span
                    className="switchboard profile_image_wrapper pl-3 pt-2"
                    data-pr-tooltip="Switchboard"
                    onClick={(e) => !loginAsAffiliateRequest && onTopbarItemClick(e, 'bureau')}
                  >
                    <i className={cx('pi pi-sync pointer ', loginAsAffiliateRequest ? 'pi-spin text-500' : '')} style={{ fontSize: '15px' }} />
                  </span>
                </>
              )}

              {/* Access inbox */}
              {managed !== 0 && (
                <>
                  <span className="mailbox profile_image_wrapper pl-3 pt-2" data-pr-tooltip="Mailbox" onClick={(e) => onTopbarItemClick(e, 'inbox')}>
                    <i className={cx('pi pi-inbox pointer ', '')} style={{ fontSize: '15px' }} />
                  </span>
                </>
              )}
            </button>
            <ul className={cx({ fadeInDown: !isMobile }, classes.fadeDown)}>{settingsMenu('Account')}</ul>
          </li>

          <li className={cx({ 'active-topmenuitem': props.activeTopbarItem === 'settings' })}>
            {!isMobile && (
              <Tooltip target=".settings-tooltip" position="bottom">
                Settings
              </Tooltip>
            )}
            <button tabIndex={-1} type="button" className="p-link settings-tooltip" onClick={(e) => onTopbarItemClick(e, 'settings')}>
              <i className={cx('topbar-icon pi pi-cog', classes.icons)} />
              <span className="topbar-item-name">{t('Settings')}</span>
            </button>
            <ul className={cx('fadeInDown', classes.fadeDown)}>{settingsMenu('Gear')}</ul>
          </li>

          <li>
            {!isMobile && (
              <Tooltip target=".logout-tooltip" position="bottom">
                Logout
              </Tooltip>
            )}
            <button
              tabIndex={-1}
              type="button"
              className="p-link logout-tooltip"
              onClick={() => {
                const totalOutstanding = getTotalOutstanding();
                if (totalOutstanding > 0) {
                  dispatch(setShowTotalOutstandingDialog({ showDialog: true }));
                } else dispatch(logout());
              }}
            >
              <i className={cx('topbar-icon pi pi-sign-out')} />
              <span className="topbar-item-name">{t('Logout')}</span>
            </button>
          </li>

          <li className="hidden md:block text-white cursor-auto">
            <div className="flex align-items-center pt-2 pr-3">{clinic?.details?.DataCenterNumber}</div>
          </li>

          {/* 
          <li className={cx({ 'active-topmenuitem': props.activeTopbarItem === 'counters' })}>
            <button className="p-link" onClick={(e) => {
              onTopbarItemClick(e, 'counters');
              // [KS] CMO-1146 - After user creates new outstanding claims, the counters on the dashboard do not get updated
              dispatch(getCounters(DCNGuid));
            }}>
              <i className={cx("pi pi-chart-bar", classes.icons)} />
              <span className="topbar-item-name">{t("Counters")}</span>
            </button>
            <ul className={cx("fadeInDown px-3 pt-0", classes.fadeDown)}>
              <li role="menuitem">
                <Counters
                  counters={counters}
                  showStatusBar={false}
                  isMobileOnly={isMobileOnly}
                />
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
